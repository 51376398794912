/* -------------------------- */
/* CORE */
* {
  margin: 0;
  padding: 0;
}

html,
body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-smooth: auto;
  font-weight: 300;
  line-height: 1.5;
  color: #444;
  height: 100vh;
  user-select: none; /* don't let the text get selected */
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

/* -------------------------- */
/* LABELS */
#c {
  width: 100%; /* let our container decide our size */
  height: 100%;
  display: block;
}
#container {
  position: relative; /* makes this the origin of its children */
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#labels {
  position: absolute; /* let us position ourself inside the container */
  left: 0; /* make our position the top left of the container */
  top: 0;
  color: #ffede5;
}
#labels > div {
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 8px;
  /*background-image: linear-gradient(315deg, #5b6467 0%, #8b939a 74%);*/
  width: 300px;
  visibility: hidden;
  text-align: center;
  position: absolute; /* let us position them inside the container */
  left: 0; /* make their default position the top left of the container */
  top: 0;
  cursor: pointer; /* change the cursor to a hand when over us */
  font-size: large;
  pointer-events: none; /* don't let mouse events to block js interactions */
  text-shadow:         /* create a black outline */ -1px -1px 0 #000,
    0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000, 1px 1px 0 #000, 0 1px 0 #000,
    -1px 1px 0 #000, -1px 0 0 #000;
}
.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  opacity: 0;
  animation-name: fadeOutOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* -------------------------- */
/* MODAL WINDOW */

.hidden {
  display: none;
  visibility: hidden;
}

.uninteractable {
  pointer-events: none;
}

.modal {
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 500px;
  align-items: flex-end;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 5;
}
footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
#modal-image {
  display: block;
  width: 100%;
  height: 200px;
  margin: auto;
  object-fit: cover;
  box-shadow: 0px 7px 5px #ccc;
  -moz-box-shadow: 0px 7px 5px #ccc;
  -webkit-box-shadow: 0px 7px 5px #ccc;
  -khtml-box-shadow: 0px 7px 5px #ccc;
}
#modal-content {
  padding: 4rem;
}

#modal-content p {
  padding-top: 15px;
  padding-bottom: 50px;
}
.modal-button-visit {
  margin-left: 20px;
}

.button-confirm {
  display: inline-block;
  outline: 0;
  cursor: pointer;
  padding: 5px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  vertical-align: middle;
  border: 1px solid;
  border-radius: 6px;
  color: #ffffff;
  background-color: #2ea44f;
  border-color: #1b1f2326;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px 0px inset;
  transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  transition-property: color, background-color, border-color;
}
.button-confirm:hover {
  background-color: #2c974b;
  border-color: #1b1f2326;
  transition-duration: 0.1s;
}

.button-reject {
  display: inline-block;
  outline: 0;
  cursor: pointer;
  padding: 5px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  vertical-align: middle;
  border: 1px solid;
  border-radius: 6px;
  color: #24292e;
  background-color: #fafbfc;
  border-color: #1b1f2326;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px 0px inset;
  transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  transition-property: color, background-color, border-color;
}
.button-reject:hover {
  background-color: #f3f4f6;
  border-color: #1b1f2326;
  transition-duration: 0.1s;
}

/* -------------------------- */
/* MUTE BUTTON */
#buttons {
  position: relative;
}
.mute-button {
  background-color: rgba(255, 0, 0, 0);
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 16px; /* Some padding */
  font-size: 16px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 5px;

  z-index: 9999;
}
.back-button {
  background-color: rgba(255, 0, 0, 0);
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 16px; /* Some padding */
  font-size: 16px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 5px;

  z-index: 9999;
}
.fa {
  transform: scale(2);
}

/* Darker background on mouse-over */
.mute-button:hover {
  color: #d3d3d3; /* White text */
}

/* -------------------------- */
/* LOADING SCREEN - PROGRESS BAR */
#loading-screen {
  z-index: 999;
  margin: auto;
  position: fixed;
  width: 80%;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
}

#progressBar {
  top: 50%;
  left: 30%;
  right: 30%;
  position: absolute;
  margin: auto;
}

#progressFill {
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #fff;
  height: 30px;
  width: 0%;
  position: absolute;
  top: 50%;
}
#buttonSliceContainer {
  font-family: "Fjalla One", sans-serif;
  text-transform: uppercase;
  height: 95vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/*--Button Styles--*/
.btn-slice {
  padding: 1.2rem 2.5rem;
  border: 2px solid #ffffff;
  border-radius: 0rem;
  color: #ffffff;
  font-size: 0.6875rem;
  font-weight: 700;
  letter-spacing: 0.3rem;
  position: relative;
  top: 20%;
  transform: scale(1.5);

  -webkit-transition: all 1000ms cubic-bezier(0.89, 0, 0.14, 1); /* older webkit */
  -webkit-transition: all 1000ms cubic-bezier(0.89, -0.17, 0.14, 1.225);
  -moz-transition: all 1000ms cubic-bezier(0.89, -0.17, 0.14, 1.225);
  -o-transition: all 1000ms cubic-bezier(0.89, -0.17, 0.14, 1.225);
  transition: all 1000ms cubic-bezier(0.89, -0.17, 0.14, 1.225); /* custom */

  -webkit-transition-timing-function: cubic-bezier(
    0.89,
    0,
    0.14,
    1
  ); /* older webkit */
  -webkit-transition-timing-function: cubic-bezier(0.89, -0.17, 0.14, 1.225);
  -moz-transition-timing-function: cubic-bezier(0.89, -0.17, 0.14, 1.225);
  -o-transition-timing-function: cubic-bezier(0.89, -0.17, 0.14, 1.225);
  transition-timing-function: cubic-bezier(
    0.89,
    -0.17,
    0.14,
    1.225
  ); /* custom */
}

.btn-slice .top {
  position: relative;
  height: 6px;
  overflow: hidden;
  top: 0;
  -webkit-transition: all 300ms cubic-bezier(1, 0, 0.025, 1); /* older webkit */
  -webkit-transition: all 300ms cubic-bezier(1, -0.055, 0.025, 1.04);
  -moz-transition: all 300ms cubic-bezier(1, -0.055, 0.025, 1.04);
  -o-transition: all 300ms cubic-bezier(1, -0.055, 0.025, 1.04);
  transition: all 300ms cubic-bezier(1, -0.055, 0.025, 1.04); /* custom */

  -webkit-transition-timing-function: cubic-bezier(
    1,
    0,
    0.025,
    1
  ); /* older webkit */
  -webkit-transition-timing-function: cubic-bezier(1, -0.055, 0.025, 1.04);
  -moz-transition-timing-function: cubic-bezier(1, -0.055, 0.025, 1.04);
  -o-transition-timing-function: cubic-bezier(1, -0.055, 0.025, 1.04);
  transition-timing-function: cubic-bezier(1, -0.055, 0.025, 1.04); /* custom */

  -webkit-transition-delay: 0.35s;
  -moz-transition-delay: 0.35s;
  -ms-transition-delay: 0.35s;
  -o-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.btn-slice .bottom {
  position: relative;
  height: 6px;
  overflow: hidden;
  bottom: 0;
  -webkit-transition: all 300ms cubic-bezier(1, 0, 0.025, 1); /* older webkit */
  -webkit-transition: all 300ms cubic-bezier(1, -0.055, 0.025, 1.04);
  -moz-transition: all 300ms cubic-bezier(1, -0.055, 0.025, 1.04);
  -o-transition: all 300ms cubic-bezier(1, -0.055, 0.025, 1.04);
  transition: all 300ms cubic-bezier(1, -0.055, 0.025, 1.04); /* custom */

  -webkit-transition-timing-function: cubic-bezier(
    1,
    0,
    0.025,
    1
  ); /* older webkit */
  -webkit-transition-timing-function: cubic-bezier(1, -0.055, 0.025, 1.04);
  -moz-transition-timing-function: cubic-bezier(1, -0.055, 0.025, 1.04);
  -o-transition-timing-function: cubic-bezier(1, -0.055, 0.025, 1.04);
  transition-timing-function: cubic-bezier(1, -0.055, 0.025, 1.04); /* custom */

  -webkit-transition-delay: 0.35s;
  -moz-transition-delay: 0.35s;
  -ms-transition-delay: 0.35s;
  -o-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.btn-slice .bottom span {
  top: -6px;
  position: absolute;
  left: 0;
}

.btn-slice:hover .top {
  top: -5px;
  -webkit-transition-delay: 0.35s;
  -moz-transition-delay: 0.35s;
  -ms-transition-delay: 0.35s;
  -o-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.btn-slice:hover .bottom {
  bottom: -5px;
  -webkit-transition-delay: 0.35s;
  -moz-transition-delay: 0.35s;
  -ms-transition-delay: 0.35s;
  -o-transition-delay: 0.35s;
  transition-delay: 0.35s;
}
.btn-slice:hover,
.btn-slice:focus,
.btn-slice:active {
  margin-left: 10px;
}
.btn-slice:before {
  content: "";
  height: 1px;
  width: 60px;
  background-color: #ffffff;
  position: absolute;
  margin-top: 6px;
  right: -35px;

  -webkit-transition: all 1000ms cubic-bezier(0.89, 0, 0.14, 1); /* older webkit */
  -webkit-transition: all 1000ms cubic-bezier(0.89, -0.17, 0.14, 1.225);
  -moz-transition: all 1000ms cubic-bezier(0.89, -0.17, 0.14, 1.225);
  -o-transition: all 1000ms cubic-bezier(0.89, -0.17, 0.14, 1.225);
  transition: all 1000ms cubic-bezier(0.89, -0.17, 0.14, 1.225); /* custom */

  -webkit-transition-timing-function: cubic-bezier(
    0.89,
    0,
    0.14,
    1
  ); /* older webkit */
  -webkit-transition-timing-function: cubic-bezier(0.89, -0.17, 0.14, 1.225);
  -moz-transition-timing-function: cubic-bezier(0.89, -0.17, 0.14, 1.225);
  -o-transition-timing-function: cubic-bezier(0.89, -0.17, 0.14, 1.225);
  transition-timing-function: cubic-bezier(
    0.89,
    -0.17,
    0.14,
    1.225
  ); /* custom */
}
.btn-slice:hover:before {
  width: 130%;
}

/*  Mouse Drag Icon */
.drag-icons {
  position: absolute;
  top: 70%;
  left: 50%;
  z-index: 999;
}
.fa-mouse-pointer {
  color: #ffffff;
  position: relative;
}
.fa-circle-o {
  color: #6495ed;
  position: relative;
  bottom: 15px;
  left: 7px;
}

.pointer-animate {
  transform: scale(3);
  animation-name: animatePointer;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  animation-duration: 1.5s;
}
@keyframes animatePointer {
  0% {
    transform: scale(3);
    left: 0px;
  }
  40% {
    transform: scale(2);
    left: 0px;
  }
  90% {
    transform: scale(2);
    left: 70px;
  }
  100% {
    transform: scale(3);
    left: 0px;
  }
}

.circle-animate {
  transform: scale(0);
  animation-name: animateCircle;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  animation-duration: 1.5s;
}
@keyframes animateCircle {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  40% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(3.5);
    opacity: 1;
  }
  60% {
    transform: scale(1.5);
    opacity: 0;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
